import React, { useState } from "react"
import { graphql, Link } from "gatsby";
import styled from '@emotion/styled';

import Container from '../components/layout/container'
import SEO from '../components/seo'

import ArrowRight from "./../icons/arrow-right.js";
import ChevronRight from "./../icons/Chevron-right.js";
import logo from "./../images/logo-symbol.svg";
import Share from "./../icons/share.svg";
import SoMeShareModal from '../components/SoMeShareModal'

// styles
import { colors } from "./../styling/colors";
import "./../styling/libraries.scss"

// data
export const query = graphql`
  query Posts {
    sanitySiteSettings {
      url
    }
    sanityPage(slug: {current: {eq: "aktuelt"}}) {
      id
      title
      intro
    }
    allSanityPost(sort: {fields: publishedAt, order: DESC}) {
      edges {
        node {
          title
          publishedAt(fromNow: true)
          slug {
            current
          }
          externalLink
          mainImage {
            alt
            asset {
              fixed(width: 340, height: 205) {
                src
              }
            }
          }
          author {
            name
            title
          }
          categories {
            title
          }
          intro
        }
      }
    }
  }
`

const ArticleStyling = styled.article`
  .block-link {
    display: block;

    img {
      opacity: 1;
      transition: .25s;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 5px ${colors.green};
    }

    &:hover {
      h2 {
        text-decoration: underline;
      }
      img {
        opacity: .8;
      }

      .external-link {
        text-decoration: none;
      }

      .readmoreLink {
        text-decoration: underline;
      }
    }

    .readmoreLink {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-left: .7em;
      }
    }
    
    .external-link {
      text-decoration: underline;
      font-weight: 600;
    }

  }

  .author {
    width: 100%;
    display: block;
    font-weight: 600;
    font-style: normal;
    margin: 0;
  }

  .author-title {
    font-style: normal;
    width: 100%;
    display: block;
  }

  .article-image {
    margin-bottom: 1em;
  }

  .button {
    background: ${colors.blue};
    transition: .25s;
    display: inline-block;
    padding: 0.5em 1em;
    border-radius: 3px;
    border: 0;

    &.circle {
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }

    &:hover {
      background: ${colors.blue09};
      box-shadow: 0 0 0 5px ${colors.green};
    }

    &:focus {
      box-shadow: 0 0 0 5px ${colors.green};
    }
  }

  .button {
    background: ${colors.blue};
    transition: .25s;
    display: inline-block;
    padding: 0.5em 1em;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &.circle {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      padding: .5em .5em .5em 4px;
    }

    &:hover {
      background: ${colors.blue09};
      // box-shadow: 0 0 0 5px ${colors.green};
    }

    &:focus {
      box-shadow: 0 0 0 5px ${colors.green};
    }
  }

`

const ExternalLinkItem = (props) => {
  return (
    <article className="mb-6" style={{'position': 'relative', 'width': '100%'}}>

      <a className="block-link mb-5" href={props.externalLink} target="_blank" rel="noreferrer" aria-label="Åpner lenke i nytt vindu">

        <span className="mb-4" style={{'display': 'flex', 'flexDirection': 'column'}}>

          <span>

            <h2 className="title is-5 my-3 mr-6">{props.title}</h2>

            <span className="mb-4">
              {props.intro}
            </span>

          </span>

          <span style={{'order': -1}} className={`image ${!props.image && 'is-16by9'}`}>

            <span className={!props.image ? "has-ratio" : ""} width="340" height="205" style={{'background': colors.blue02}}>

              {props.image &&
                <img 
                  width="340"
                  height="205"
                  className="article-image" 
                  src={props.image.asset && props.image.asset.fixed && props.image.asset.fixed.src} 
                  alt={props.image.alt ? props.image.alt : ''}
                />
              }
              
            </span>

          </span>

        </span>

        <footer>

          <span className="is-flex is-align-items-center external-link">

            <span className="mr-3" style={{'display': 'flex'}}><ArrowRight fill={props.className && props.className.includes('button') ? 'white' : 'black'} /></span>

            <span className="linkText">
              {props.externalLink.split('/')[2].split('www.').filter(item => item)[0]}
            </span>

          </span>

        </footer>

      </a>

      {props.tags}

      <div className="image is-16by9" 
        style={{
          'top': '0',
          'position': 'absolute', 
          'width': '100%',
          'pointerEvents': 'none'
        }}>

        <div className="has-ratio"></div>

        <button
          style={{'position': 'absolute', 'right': '0', 'bottom': '-60px', 'pointerEvents': 'auto' }}
          className="button circle ml-5" 
          onClick={() => props.handleClickShare(props.externalLink)}>
            <span className="is-sr-only">Del artikkelen {props.title}</span>
            <span className="icon"><Share /></span>
        </button>

      </div>

    </article>
  )
}


const Item = (props) => {
  return (

    <article style={{'position': 'relative', 'width': '100%'}}>

      <Link className="block-link" to={props.slug}>

        <div style={{'display': 'flex', 'flexDirection': 'column'}}>

          <div className="mb-4">
            
            <h2 className="title is-5 my-3 mr-6">{props.title}</h2>

            <div className="mb-4">
              {props.intro}
            </div>

          </div>

          <span style={{'order': -1}} className={`image ${!props.image && 'is-16by9'}`}>

            <div className={!props.image ? "has-ratio" : ""} width="340" height="205" style={{'background': colors.blue02}}>

              <div className="mb-4">

                <div style={{'zIndex': '1','display': 'flex', 'position': 'absolute', 'right': '1em', 'top': '1em', 'justifyContent': 'center', 'width': '48px', 'height': '48px', 'background': '#fff', 'borderRadius': '100%'}}>
                  <img src={logo} alt="MED.hjelper logo" style={{'width': '26px'}} />
                </div>
            
                {props.image &&
                  <img 
                    className="article-image" 
                    src={props.image.asset && props.image.asset.fixed && props.image.asset.fixed.src} 
                    alt={props.image.alt ? props.image.alt : ''}
                    style={{'margin': 'auto'}} 
                  />
                }
                    
              </div>
            
            </div>

          </span>

        </div>

        <footer>
          {props.footer}
        </footer>
        
      </Link>

      {props.tags}

      <div className="image is-16by9" 
        style={{
          'top': '0',
          'position': 'absolute', 
          'width': '100%',
          'pointerEvents': 'none'
        }}>

        <div className="has-ratio"></div>

        <button
          style={{'position': 'absolute', 'right': '0', 'bottom': '-60px', 'pointerEvents': 'auto' }}
          className="button circle ml-5" 
          onClick={() => props.handleClickShare(props.slug)}>
            <span className="is-sr-only">Del artikkelen {props.title}</span>
            <span className="icon"><Share /></span>
        </button>

      </div>

    </article>
    
  )
}

// markup
function Aktuelt({data}) {

  const [shareUrl, setShareUrl] = useState('')
  const [shareTitle, setShareTitle] = useState('')
  const pageData = data ? data.sanityPage : null
  const url = data.sanitySiteSettings.url

  return (
    <Container>

      <SEO 
        title="Aktuelt"
        description={pageData.intro}
        pathname="/aktuelt"
      />

      <div className="container">

        <div className="section py-0">
          
          <div className="columns">
            <div className="column is-offset-1 is-6 mt-6">

              {pageData.title || pageData.displayTitle ?
                <h1 className="title is-size-1">{pageData.displayTitle ? pageData.displayTitle : pageData.title}</h1>
              : null}
              
              {pageData.intro && <p className="is-size-5">{pageData.intro}</p>}

            </div>
          </div>

        </div>

      </div>

      <div>
        <div className="container">

          <div className="section">

            <SoMeShareModal 
              isOpen={shareUrl !== ''}
              handleClose={() => {
                setShareTitle('');
                setShareUrl('');
              }}
              url={shareUrl}
              title={shareTitle}
            />

            <div className="columns">

              <div className="column is-offset-1 is-10">

                <div className="columns is-multiline">

                  {data.allSanityPost && data.allSanityPost.edges && data.allSanityPost.edges
                    .map((item, index) => 
                      <ArticleStyling className="column is-half-tablet is-one-third-desktop" key={item.node.title}>

                        <div className="is-flex">       

                          {item.node.externalLink
                            ? <ExternalLinkItem 
                                {...item.node}
                                externalLink={item.node.externalLink}
                                title={item.node.title}
                                image={item.node.mainImage}
                                intro={item.node.intro}
                                handleClickShare={() => {
                                  setShareUrl(item.node.externalLink); 
                                  setShareTitle(item.node.title)
                                }}
                                tags={
                                  <div style={{'fontSize': '16px'}} className="buttons">
                                    {item.node.categories && item.node.categories.map((category, tagIndex) => 
                                      <span key={"aktuelt"+index+'tag'+tagIndex} className="tag" style={{'background': colors.green02, 'padding': '.5em 1em', 'marginBottom': '.5em', 'marginRight': '1em', 'borderRadius': '100px', 'display': 'inline-block'}}>{category.title}</span>
                                    )}
                                  </div>
                                }
                              />
                            : <Item 
                                {...item.node}
                                slug={item.node.slug.current}
                                title={item.node.title}
                                image={item.node.mainImage}
                                intro={item.node.intro}
                                handleClickShare={(e) => {
                                  setShareUrl(`${url}/aktuelt/${e}/`)
                                  setShareTitle(item.node.title)
                                }}
                                footer={
                                  <div className="mb-5 is-flex is-justify-content-space-between is-align-items-start">
                                    <div style={{'fontSize': '16px'}}>
                                      <span className="author block">{item.node.author.name}</span>
                                      <em className="author-title block m-0">{item.node.author.title}</em>
                                    </div>
                                    <div className="is-flex is-justify-content-flex-end">
                                      <span className="readmoreLink">Les mer <ChevronRight fill="black" /></span>
                                    </div>
                                  </div>
                                }
                                tags={
                                  <div style={{'fontSize': '16px'}} className="buttons">
                                    {item.node.categories && item.node.categories.map((category, tagIndex) => 
                                      <span key={"aktuelt"+index+'tag'+tagIndex} className="tag" style={{'background': colors.green02, 'padding': '.5em 1em', 'marginBottom': '.5em', 'marginRight': '1em', 'borderRadius': '100px', 'display': 'inline-block'}}>{category.title}</span>
                                    )}
                                  </div>
                                }
                              />
                          }

                        </div>
                        
                      
                      </ArticleStyling>
                    )
                  }

                </div>

              </div>

            </div>

          </div>

        </div>
      </div>
    </Container>
  )
}

export default Aktuelt
