import React, { useRef } from 'react';
import styled from '@emotion/styled';
import FocusTrap from 'focus-trap-react'

import Facebook from "./../icons/Facebook";
import Twitter from "./../icons/Twitter";
import Email from "./../icons/email.svg";
import X from "./../icons/x.svg";
import LinkIcon from "./../icons/link.svg";

import Button from "./editorial/button";
import Link from "./editorial/link";
import { breakpoints } from "../styling/breakpoints";
import { colors } from "../styling/colors";
import { typescale } from "../styling/typescale";

const InputWrapper = styled.span`
  background: ${colors.green02};
  border-radius: 3px;
  flex: 1;
  padding: 0;
  position: relative;
  margin-right: 1em;
  padding-left: 1em;
  position: relative;
  display: flex;
  align-items: center;

  .input {
    background: transparent;
    box-shadow: none;
    border: 0;
  }
`
const CloseButtonStyling = styled.button`
  background: transparent;
  border-radius: 3px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  padding: 0.5em;
  outline: none;
  transition: .25s;

  &.circle {
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }

  &:hover {
    box-shadow: 0 0 0 5px ${colors.green};
  }

  &:focus {
    box-shadow: 0 0 0 5px ${colors.green};
  }
`
const Modal = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  opacity: ${props => props.isOpen ? '1' : '0'};
  height: ${props => props.isOpen ? 'auto' : '0'};
  padding: ${props => props.isOpen ? '0 0 3em' : '0'};
  background: #fff;
  border-radius: 4px;
  max-width: 95%;
  margin: auto;
  overflow: hidden;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (${breakpoints.tablet}) {
    width: 900px; 
  }

  .textWrapper {
    margin: 0 auto;
    padding: 1em;

    @media only screen and (${breakpoints.tablet}) {
      max-width: 70%;
    }
  }
`
const Icon = styled.div`
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (${breakpoints.mobile}) {
    width: 90px;
    height: 90px;
  }

  svg {
    width: 46px;
    height: 46px;
  }
`
const Backdrop = styled.div`
  background: rgba(0, 0, 0, .3);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 90;
`

function SoMeShareModal ({ title, url, handleClose, isOpen }) {

  const inputFieldRef = useRef(null);

  function copyToClipboard(e) {
    inputFieldRef.current.select();
    navigator.clipboard.writeText(inputFieldRef.current.value)
    e.target.focus();
    e.preventDefault();
  };

  return (
    <FocusTrap active={isOpen}>
      <div>

        {isOpen &&
          <Backdrop isOpen={isOpen} onClick={handleClose} />
        }
        
        <Modal 
          isOpen={isOpen}
          aria-modal={isOpen}
        >

          <div>

            <div style={{
              'background': colors.green02, 
              'display': 'block', 
              'padding': '1em',
              'marginBottom': '3em',
              'textAlign': 'center',
            }}>
              
              <div className="is-flex is-justify-content-center	is-align-content-center	is-align-items-center	">
                <h2 style={{'fontSize': typescale['title-3'], 'flex': '1'}}>Hjelp oss å dele kunnskap!</h2>
                
                {/* Added invisible button as first button to set focus here so first actual button don't get focus styling at once when opening modal */}
                <button style={{'opacity': '0'}}>Skjult knapp</button>

                {/* Actual close button */ }
                <CloseButtonStyling className="circle" onClick={handleClose}>
                  <span className="icon"><X /></span>
                  <span className="is-sr-only">Lukk vindu</span>
                </CloseButtonStyling>

              </div>

            </div>

            <div className="textWrapper">

              <p style={{'maxWidth': '530px', 'margin': '0 auto 1em'}}>La flere lære mer om kliniske studier, og del denne artikkelen med andre du kjenner.</p>

              <div className="is-flex is-justify-content-center	mb-5 pb-5">

                <Link 
                  className="button mr-4 p-0" 
                  linkText={ <Icon><span className="icon is-large"><Facebook fill={'white'} /></span></Icon> } 
                  externalURL={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                />

                <Link 
                  className="button mr-4 p-0" 
                  linkText={<Icon><span className="icon is-large"><Twitter fill={'white'} /></span></Icon>}
                  externalURL={`http://twitter.com/share?text=${title}&url=${url}`}
                />

                <Link 
                  className="button mr-4 p-0" 
                  linkText={<Icon><span className="icon is-large"><Email fill={'white'} /></span></Icon>}
                  externalURL={`mailto:?subject=${title}&body=${url}`}
                />

              </div>


              <div>

                <form>

                  <label className="mb-3" style={{'display': 'block'}}>
                    Du kan også kopiere lenken for å dele
                  </label>

                  <div style={{'display': 'flex'}}>

                    <InputWrapper>

                      <LinkIcon />

                      <input 
                        type="text"
                        className="input"
                        defaultValue={url}
                        ref={inputFieldRef}
                      />

                    </InputWrapper>

                    <Button 
                      buttonText="Kopier"
                      onClick={copyToClipboard}
                    />
                    
                  </div>

                </form>
              </div>

            </div>

            <div style={{'width': '100%', 'textAlign': 'center'}}>
              <Button 
                transparent underlined
                buttonText="Kansellere" 
                onClick={handleClose}
              />
            </div>
                
          </div>

        </Modal>

      </div>
    </FocusTrap>

  )
            
}

export default SoMeShareModal;
